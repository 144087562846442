var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-navigation-drawer', {
    attrs: {
      "permanent": "",
      "app": "",
      "mini-variant": !_vm.IS_NAVIGATION_OPEN
    }
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', {
    on: {
      "click": _vm.toggleNavigation
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v(" " + _vm._s(_vm.navigationIcon) + " ")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_vm._v(" Your Application ")])])], 1)], 1), _c('v-divider'), _vm._l(_vm.sections, function (section) {
    return _c('div', {
      key: section.key
    }, [_vm.isComplainSection(section) ? [_vm.checkIsSectionVisible(section) ? _c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.selectSection(section.key);
        }
      }
    }, [_c('v-tooltip', {
      attrs: {
        "right": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on;
          return [_c('v-list-item-icon', _vm._g({}, on), [_c('v-icon', [_vm._v(" " + _vm._s(section.icon) + " ")])], 1)];
        }
      }], null, true)
    }, [_c('span', [_vm._v(_vm._s(section.title))])]), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(section.title))])], 1)], 1) : _vm._e()] : _vm._e()], 2);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }