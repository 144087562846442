<template>
  <v-navigation-drawer
    permanent
    app
    :mini-variant="!IS_NAVIGATION_OPEN"
  >
    <v-list
      dense
    >
      <v-list-item @click="toggleNavigation">
        <v-list-item-icon>
          <v-icon>
            {{ navigationIcon }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <div class="d-flex justify-space-between">
              Your Application
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <div
        v-for="section in sections"
        :key="section.key"
      >
      <template v-if="isComplainSection(section)">
        <v-list-item
          link
          v-if="checkIsSectionVisible(section)"
          @click="selectSection(section.key)"
        >
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-list-item-icon v-on="on">
                <v-icon>
                  {{ section.icon }}
                </v-icon>
              </v-list-item-icon>
            </template>
            <span>{{ section.title }}</span>
          </v-tooltip>
          <v-list-item-content>
            <v-list-item-title>{{ section.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>

import { mapGetters } from 'vuex';
import { checkIsSectionVisible } from '@/services/section';
import not from '@/utils/common';

export default {
  name: 'ApplicationNavigation',
  props: {
    sections: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters([
      'IS_NAVIGATION_OPEN',
      'SELECTED_SECTION_ID',
      'COMPLAIN_STATUS',
    ]),

    isSubApplication() {
      const subApplicationRoute = 'SubApplicationAdviser';
      const isSubApplication = Boolean(this.$route.name === subApplicationRoute);

      return isSubApplication;
    },

    applicationId() {
      return this.$store.getters.APPLICATION_ID;
    },

    navigationIcon() {
      const openIcon = 'mdi-chevron-right';
      const closeIcon = 'mdi-chevron-left';

      return this.IS_NAVIGATION_OPEN
        ? closeIcon
        : openIcon;
    },
  },

  methods: {
    isComplainSection(section) {
      const isComplain = Boolean(section.key === 'complain' && !this.COMPLAIN_STATUS);

      return !isComplain;
    },

    toggleNavigation() {
      this.$store.dispatch('toggleNavigation', not(this.IS_NAVIGATION_OPEN));
    },

    selectSection(sectionId) {
      this.goToSection(sectionId);

      const sectionData = {
        applicationId: this.applicationId,
        sectionId,
      };

      this.$store.dispatch('selectSection', sectionData);
    },

    goToSection(sectionId) {
      try {
        this.$vuetify.goTo(`#${sectionId}`, {
          duration: 300,
          offset: 0,
          easing: 'easeInOutCubic',
        });
      } catch (error) {
        console.log(error);
      }
    },

    goToSelectedSection() {
      const sectionId = this.SELECTED_SECTION_ID(this.applicationId);

      const isSelectedSectionIdUndefined = Boolean(!sectionId);
      if (isSelectedSectionIdUndefined) {
        return;
      }

      this.goToSection(sectionId);
    },

    async checkIsSectionVisible(section) {
      const isSectionVisible = await checkIsSectionVisible(section);

      return isSectionVisible;
    },
  },
};
</script>
<style lang="scss">
.v-navigation-drawer__content {
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #eceff1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #3f51b5;
  }

  scrollbar-color: #3f51b5 #eceff1;
  scrollbar-width: thin;
}
</style>
