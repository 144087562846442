var render = function () {
  var _vm$APPLICATION2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-overlay', {
    attrs: {
      "value": _vm.isLoading,
      "z-index": "9999"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "64"
    }
  })], 1), _c('v-container', [_c('ApplicationNavigation', {
    attrs: {
      "sections": _vm.sections,
      "user-role": "client"
    }
  }), _c('v-row', {
    ref: "sections",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "lg": "9"
    }
  }, _vm._l(_vm.sections, function (section) {
    var _vm$APPLICATION, _vm$APPLICATION$data;

    return _c('div', {
      key: section.key
    }, [section.visibility ? _c(section.component, {
      key: section.key,
      tag: "component",
      attrs: {
        "section": section,
        "existedData": (_vm$APPLICATION = _vm.APPLICATION) === null || _vm$APPLICATION === void 0 ? void 0 : (_vm$APPLICATION$data = _vm$APPLICATION.data) === null || _vm$APPLICATION$data === void 0 ? void 0 : _vm$APPLICATION$data[section.key]
      }
    }) : _vm._e()], 1);
  }), 0)], 1), _c('TheBottomNavigation', {
    attrs: {
      "unsavedSections": _vm.IS_UNSAVED_SECTION_EXIST,
      "showApplicationLink": true,
      "mainApplicationId": (_vm$APPLICATION2 = _vm.APPLICATION) === null || _vm$APPLICATION2 === void 0 ? void 0 : _vm$APPLICATION2.parentId,
      "isSubApplication": true,
      "user-role": "adviser"
    }
  }, [_vm.isNotDraft ? _c('v-btn', {
    on: {
      "click": _vm.moveToDraft
    }
  }, [_c('span', [_vm._v("Move to drafts")]), _c('v-icon', [_vm._v("mdi-delete")])], 1) : _c('v-btn', {
    on: {
      "click": _vm.restoreFromDraft
    }
  }, [_c('span', [_vm._v("Restore")]), _c('v-icon', [_vm._v("mdi-restore")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }