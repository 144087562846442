var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-bottom-navigation', {
    attrs: {
      "app": ""
    }
  }, [_vm.canShowApplicationLink ? _c('v-btn', {
    attrs: {
      "link": "",
      "exact": ""
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.goToMainApplication.apply(null, arguments);
      }
    }
  }, [_c('span', [_vm._v("Main application")]), _c('v-icon', [_vm._v("mdi-text-box-multiple-outline")])], 1) : _vm._e(), _c('v-btn', {
    attrs: {
      "color": _vm.colorSaveBtn,
      "disabled": !this.unsavedSections
    },
    on: {
      "click": _vm.saveApplication
    }
  }, [_c('span', [_vm._v("Save")]), _c('v-icon', [_vm._v("mdi-cloud-upload")])], 1), _vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }