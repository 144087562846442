<template>
  <div>
    <v-overlay
      :value="isLoading"
      z-index="9999"
    >
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-container>
      <ApplicationNavigation
        :sections="sections"
        user-role="client"
      />

      <v-row justify="center" ref="sections">
        <v-col lg="9">
          <div
            v-for="section in sections"
            :key="section.key"
          >
            <component
              v-if="section.visibility"
              :is="section.component"
              :key="section.key"
              :section="section"
              :existedData="APPLICATION?.data?.[section.key]"
            />
          </div>
        </v-col>
      </v-row>

      <TheBottomNavigation
        :unsavedSections="IS_UNSAVED_SECTION_EXIST"
        :showApplicationLink="true"
        :mainApplicationId="APPLICATION?.parentId"
        :isSubApplication="true"
        user-role="adviser"
      >
        <v-btn
          @click="moveToDraft"
          v-if="isNotDraft"
        >
          <span>Move to drafts</span>
          <v-icon>mdi-delete</v-icon>
        </v-btn>

        <v-btn
          @click="restoreFromDraft"
          v-else
        >
          <span>Restore</span>
          <v-icon>mdi-restore</v-icon>
        </v-btn>
      </TheBottomNavigation>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import not from '@/utils/common';
import { flatten } from '@/utils/object';

import TheBottomNavigation from '@/components/TheBottomNavigation.vue';
import ApplicationNavigation from '@/components/ApplicationNavigation.vue';

import { APPLICATION_STATUSES } from '@/models/helpers/consts';

export default {
  components: {
    TheBottomNavigation,
    ApplicationNavigation,

    PersonalDetailsSection: () => import('@/components/SubApplications/Sections/PersonalDetailsSection.vue'),
    EmploymentsSection: () => import('@/components/SubApplications/Sections/EmploymentsSection.vue'),
    AddressHistorySection: () => import('@/components/SubApplications/Sections/AddressHistorySection.vue'),
  },

  data() {
    return {
      isLoading: false,

      sections: [
        {
          key: 'personal_details',
          component: 'PersonalDetailsSection',
          title: 'Personal details of Applicant',
          icon: 'mdi-account-check-outline',
          visibility: true,
          access: ['client', 'adviser'],
          payloadKey: 'personalDetails',
        },

        {
          key: 'employments',
          component: 'EmploymentsSection',
          title: 'Employments Details',
          icon: 'mdi-briefcase-outline',
          visibility: true,
          access: ['client', 'adviser'],
          payloadKey: 'employments',
        },

        {
          key: 'address_history',
          component: 'AddressHistorySection',
          title: 'Address History',
          icon: 'mdi-home-edit',
          visibility: true,
          access: ['client', 'adviser'],
          payloadKey: 'addressHistory',
        },
      ],
    };
  },

  computed: {
    ...mapGetters([
      'APPLICATION',
      'EDITED_APPLICATION',
      'IS_UNSAVED_SECTION_EXIST',
      'CURRENT_EDIT_ROLE',
    ]),

    isDraft() {
      return this.APPLICATION?.status === APPLICATION_STATUSES.draft;
    },

    isNotDraft() {
      console.log(not(this.isDraft));
      return not(this.isDraft);
    },
  },

  methods: {
    getSectionKey(sectionData) {
      const data = Array.isArray(sectionData)
        ? sectionData
        : flatten(sectionData);

      const key = JSON.stringify(data);

      return key;
    },

    async moveToDraft() {
      const payload = {
        status: APPLICATION_STATUSES.draft,
      };

      try {
        await this.$store.dispatch('storeCommonData', payload);
      } catch (error) {
        console.error(error);
      }
    },

    async restoreFromDraft() {
      const payload = {
        status: APPLICATION_STATUSES.active,
      };

      try {
        await this.$store.dispatch('storeCommonData', payload);
      } catch (error) {
        console.error(error);
      }

      this.APPLICATION.status = APPLICATION_STATUSES.active;
    },

    async getApplication() {
      const { id, parentId } = this.$route.params;
      const payload = {
        id,
        parentId,
      };

      await this.$store.dispatch('getApplication', payload);
    },
  },

  async created() {
    this.isLoading = true;

    await this.getApplication();

    this.isLoading = false;
  },
};
</script>
