<template>
  <v-bottom-navigation app>
    <v-btn
      v-if="canShowApplicationLink"
      link
      exact
     @click.prevent="goToMainApplication"
    >
      <span>Main application</span>
      <v-icon>mdi-text-box-multiple-outline</v-icon>
    </v-btn>
    <v-btn
      @click="saveApplication"
      :color="colorSaveBtn"
      :disabled="!this.unsavedSections"
    >
      <span>Save</span>

      <v-icon>mdi-cloud-upload</v-icon>
    </v-btn>
    <slot></slot>
  </v-bottom-navigation>
</template>

<script>
import { USER_ROLES } from '@/models/helpers/consts';

export default {
  name: 'TheBottomNavigation',

  props: {
    unsavedSections: {
      type: Boolean,
      required: true,
    },

    userRole: {
      type: String,
      default: 'client',
    },

    showApplicationLink: {
      type: Boolean,
      default: false,
    },

    isSubApplication: {
      type: Boolean,
      default: false,
    },

    mainApplicationId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      copyAlert: false,
      copyDialog: false,
    };
  },

  computed: {
    canShowApplicationLink() {
      const canShowApplicationLink = Boolean(this.showApplicationLink && this.mainApplicationId);

      return canShowApplicationLink;
    },

    applicationRouteName() {
      const isUserClient = Boolean(this.userRole === USER_ROLES.client);
      if (isUserClient) {
        return 'Application';
      }

      return 'ApplicationAdviser';
    },

    saveApplicationMethodName() {
      return 'saveApplication';
    },

    colorSaveBtn() {
      if (this.unsavedSections) {
        return 'warning';
      }

      return '';
    },
  },

  methods: {
    async saveApplication() {
      await this.$store.dispatch(this.saveApplicationMethodName);
    },

    goToMainApplication() {
      this.$router.push({ name: 'ApplicationAdviser', params: { id: this.mainApplicationId } });
    },
  },
};
</script>
