import firebase from 'firebase/app';
import 'firebase/auth';

import { getUserInfo } from './firebase';
import { AVAILABLE_USER_ROLES } from '../models/helpers/consts';

const hasAccess = async (roles = AVAILABLE_USER_ROLES) => {
  const response = await getUserInfo(firebase.auth().currentUser.uid);
  const user = response.data();

  const isUserHasAccess = Boolean(roles?.includes(user?.role));

  return isUserHasAccess;
};

const checkIsSectionVisible = async (section) => {
  const canUserShowSection = await hasAccess(section.access);
  const isShowSection = Boolean(section.visibility && canUserShowSection);

  return isShowSection;
};

export {
  hasAccess,
  checkIsSectionVisible,
};
